export interface News {
  title: string
  content: string
  link: string
  date: Date
}

export const newsList: News[] = [
  {
    title: "Championnat Régional salle 2025",
    content: "La période sélective est terminée. Confirmez votre inscription avant le 30 janvier.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2025, 0, 27),
  },
  // {
  //   title: "Championnat Régional salle 2025",
  //   content: "Les pré-inscriptions pour les championnats régionaux en salle (à Troyes les 1 et 2 février) sont ouvertes.",
  //   link: "/vie-sportive/championnats-regionaux/",
  //   date: new Date(2024, 11, 21),
  // },
  {
    title: "Candidature Championnats Régionaux 2026",
    content: "Responsables de clubs, vous avez la possibilité de proposer votre candidature à l'organisation d'un championnat régional pour la saison prochaine.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2024, 11, 2),
  },
  {
    title: "Assemblée Générale Élective 2024",
    content: "L'AG élective pour renouveler le Comité du CRTA Grand Est aura lieu en distanciel le 30 novembre 2024. Vous trouverez la convocation (pour les présidents de clubs) et la composition de la liste candidate en suivant ce lien",
    link: "/comite-regional/pv-ag/",
    date: new Date(2024, 10, 8),
  },
]
